import React, { FC } from 'react';

import SizeGuideTrigger from '../../../SizeGuide/SizeGuideTrigger';
import { StyledSizesContainer, StyledSizeTypeToggle } from './SizeTypeToggleStyles';

interface SizeTypeToggleStructureProps {
  handleSizeTypeChange: (type: string) => void;
  sizeType: string;
  types: [string, string];
  pageName: string | null;
  isDesktop?: boolean;
  isFindInStore?: boolean;
  sizeGuideToggle: boolean;
  showSizeTypeToggle?: boolean;
}

const SizeTypeToggleStructure: FC<SizeTypeToggleStructureProps> = ({
  handleSizeTypeChange,
  sizeType,
  types,
  pageName,
  isDesktop,
  isFindInStore,
  sizeGuideToggle,
  showSizeTypeToggle,
}) => (
  <StyledSizesContainer>
    {showSizeTypeToggle && (
      <div>
        {types.map((type) => (
          <StyledSizeTypeToggle
            $isActive={sizeType === type}
            key={type}
            onClick={() => handleSizeTypeChange(type)}
            type="button"
            data-hookid={`${pageName}${isFindInStore ? 'FindInStore' : ''}${type}SizesLink`}
          >
            {type}
          </StyledSizeTypeToggle>
        ))}
      </div>
    )}
    {!isFindInStore && isDesktop && sizeGuideToggle && <SizeGuideTrigger target="size-guide" />}
  </StyledSizesContainer>
);

export default SizeTypeToggleStructure;
