import styled from 'styled-components';

export const StyledSizeGuideButton = styled.button`
  ${({ theme }) => theme.types.tertiaryLinkStyles};
  border: 0;
  background: transparent;
  cursor: pointer;
  text-transform: unset;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.vars.black};
`;

export const StyledSizeGuideButtonKgRedesign = styled.button`
  display: none;

  @media ${({ theme }) => theme.devices.large} {
    display: block;
  }

  ${({ theme }) => theme.types.tertiaryLinkStyles};
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  font-weight: 400;
  line-height: normal;
  text-wrap: nowrap;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.vars.black};
`;
