import {
  Product,
  ProductImage,
  ProductImageInfo,
  ProductListItem,
  ProductSize,
  SizeLabels,
} from '../../../types/middleware-types';
import addToDataLayer from '../addToDataLayer';

export type DatalayerProduct =
  | (Product & { variants: Product[] })
  | (ProductListItem & { sizes: ProductSize[] });

export interface FormattedDatalayerProduct {
  brand: string;
  category: string;
  variant: string;
  colour: string;
  previousUrl: string;
  id: string;
  sku: string;
  inStock: string;
  name: string;
  price: string;
  quantity: number;
  saleProduct: string;
  newIn: string;
  size: string;
  sizesInStock: string;
  sizesNoStock: string;
  stockLocation: string;
  totalSizes: number;
  sizesAvailable: number;
  sizesNotAvailable: number;
  url: string;
  productImageUrl: Array<ProductImage> | Array<ProductImageInfo>;
  wasPrice?: string;
  markdown?: string;
  discountPercentage?: string;
  variants?: string;
  variantsAvailable?: string;
  variantsUnavailable?: string;
  variantsNoStock?: string;
}

const formatBoolean = (bool: boolean): string => (bool ? 'yes' : 'no');

const getSize = (
  sizes: Array<ProductSize & { sizes?: SizeLabels }>,
  sku: string,
):
  | (ProductSize & {
    sizes?: SizeLabels;
  })
  | false
  | undefined => {
  if (sizes[0].labels?.original === 'NOSZ' || sizes[0].sizes?.original === 'NOSZ') {
    return false;
  }
  return sizes.find((size) => size.sku === sku);
};

const getInStockSizes = (
  sizes: Array<ProductSize & { sizes?: SizeLabels }>,
  type: keyof SizeLabels,
): Array<string | null | undefined> => {
  return Array.isArray(sizes)
    ? sizes.filter((size) => size.inStock).map((s) => (s.labels ? s.labels[type] : s.sizes?.[type]))
    : [];
};

const getNoStockSizes = (
  sizes: Array<ProductSize & { sizes?: SizeLabels }>,
): Array<string | null | undefined> => {
  return Array.isArray(sizes)
    ? sizes.filter((size) => !size.inStock).map((s) => s.labels?.uk || s.sizes?.uk)
    : [];
};

const getAllVariants = (variants: Array<Product>): Array<string> => {
  return variants.map((variant) => variant.colour.toLowerCase());
};

const getVariantsNoStock = (variants: Array<Product>): Array<string> | boolean => {
  const noStockVariants = variants.filter((variant) => getNoStockSizes(variant.sizes));
  return noStockVariants.length > 0 && noStockVariants.map((v) => v.colour.toLowerCase());
};

const getVariantsUnavailable = (variants: Array<Product>): Array<string> => {
  return variants
    .filter((variant) => variant.sizes.length === 0)
    .map((v) => v.colour.toLowerCase());
};

const getVariantsavailable = (variants: Array<Product>): Array<string> => {
  return variants.filter((variant) => variant.sizes.length > 0).map((v) => v.colour.toLowerCase());
};

const isPdpProduct = (product: DatalayerProduct): product is Product & { variants: Product[] } =>
  'breadcrumbs' in product;

const formatAddToBasket = (
  product: DatalayerProduct,
  sku: string,
  selectedSize: string | null,
  page: string | null,
  datalayerAddToBasketSizesType: keyof SizeLabels,
  currencyData: {
    code: string,
    symbol: string
  }
): void => {
  const pathnameCategory =
    page === 'pdp'
      ? window.location.pathname.split('/').slice(0, -1).join('/').substring(1)
      : window.location.pathname.substring(1);

  const formattedProduct: FormattedDatalayerProduct = {
    brand: product.brand.toLowerCase(),
    category:
      isPdpProduct(product) && product.breadcrumbs && product.breadcrumbs.length > 0
        ? product.breadcrumbs[product.breadcrumbs.length - 1]?.url?.substring(1)
        : pathnameCategory,
    variant: product.colour ? product.colour.toLowerCase() : '',
    colour: product.colour ? product.colour.toLowerCase() : '',
    previousUrl: document.referrer,
    id: product.lineNumber,
    sku,
    inStock: formatBoolean(product.sizes.length > 0),
    name: product.name.toLowerCase(),
    price: Number(product.price.now).toFixed(2),
    quantity: 1,
    saleProduct: formatBoolean(product.sale),
    newIn: formatBoolean(product.newIn),
    size: selectedSize ?? '',
    sizesInStock: getInStockSizes(product.sizes, datalayerAddToBasketSizesType)
      .toString()
      .split(',')
      .join(', '),
    sizesNoStock: getNoStockSizes(product.sizes).toString().split(',').join(', '),
    stockLocation: 'web',
    totalSizes: product.sizes.length,
    sizesAvailable: getInStockSizes(product.sizes, datalayerAddToBasketSizesType).length,
    sizesNotAvailable: getNoStockSizes(product.sizes).length,
    url: window.location.href,
    productImageUrl: product?.media?.productImages,
  };
  if (product.price.was) {
    formattedProduct.wasPrice = Number(product.price.was).toFixed(2);
    formattedProduct.markdown = (Number(product.price.was) - Number(product.price.now)).toFixed(2);
    formattedProduct.discountPercentage = (
      (Number(product.price.now) / Number(product.price.was)) *
      100
    )
      .toFixed(2)
      .toString();
  }

  // we only have variants on the PDP
  if (isPdpProduct(product) && product.variants) {
    formattedProduct.variantsNoStock = getVariantsNoStock(product.variants)
      .toString()
      .split(',')
      .join(', ');
    formattedProduct.variantsAvailable = getVariantsavailable(product.variants)
      .toString()
      .split(',')
      .join(', ');
    formattedProduct.variantsUnavailable = getVariantsUnavailable(product.variants)
      .toString()
      .split(',')
      .join(', ');
    formattedProduct.variants = getAllVariants(product.variants).toString().split(',').join(', ');
  }

  if (!selectedSize) {
    const size = getSize(product.sizes, sku);
    if (size) {
      formattedProduct.size =
        `${size?.labels?.uk} / ${size?.labels?.eu}` || `${size.sizes?.uk} / ${size.sizes?.eu}`;
    }
  }

  addToDataLayer({
    event: 'addToBasket',
    eventCategory: 'ecommerce',
    eventAction: 'add to basket',
    eventLabel: product.lineNumber,
    eventValue: Number(product.price.now).toFixed(2),
    ecommerce: {
      currencyCode: currencyData.code,
      add: {
        products: [formattedProduct],
      },
    },
  });
};

export default formatAddToBasket;
