import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { usePathname } from 'next/navigation';
import { useTheme } from 'styled-components';

import {
  updateAddingStatus,
  updateAddProductToBag,
} from "../../../features/bag/actions";
import { selectAddingStatus } from "../../../features/bag/selectors";
import { selectToggles } from "../../../features/static";
import { ADD_PRODUCT_TO_CART, ADD_PRODUCT_TO_CART_TOGGLED } from '../../../graphQl/mutations/minibag';
import { Minibag } from '../../../types/middleware-types';
import { AddingStatus } from '../../../types/state-types';
import useHeaderNavigation from '../useHeaderNavigation';
import useMobileWatcher from '../useMobileWatcher';
import usePageName from "../usePageName";

interface UseAddToBag {
  addToBag: (product: AddToBagProduct, price: string, url: string) => Promise<void>;
  addToBagError: boolean;
  isAddingToBag: boolean;
}

interface AddToBagProduct {
  quantity: number;
  sku: string;
  lineNumber: string;
}

const useAddToBag = (): UseAddToBag => {
  const theme = useTheme();
  const path = usePathname();
  const toggles = useSelector(selectToggles)
  const [addToBagError, setAddToBagError] = useState(false);
  const [isAddingToBag, setIsAddingToBag] = useState(false);

  const { toggleMiniBag } = useHeaderNavigation();
  const bagDispatch = useDispatch();
  const addingStatus = useSelector(selectAddingStatus);

  const { isMobile, isTablet } = useMobileWatcher(['mobile', 'tablet'], theme.vars);
  const isMobileOrTable = isMobile || isTablet;
  const { pageName } = usePageName();

  const finalQuery = toggles?.engs13268AddExtraId ? ADD_PRODUCT_TO_CART_TOGGLED : ADD_PRODUCT_TO_CART

  const [addProductToBag] = useMutation<{ addProductToBag: Minibag }>(finalQuery);

  const addToBag = async (product: AddToBagProduct): Promise<void> => {
    bagDispatch(updateAddingStatus(AddingStatus.Adding));

    setIsAddingToBag(true);

    const newRelicSender = (error: string) => {
      const sendToNewRelic = typeof newrelic === 'object' && typeof window !== 'undefined';
      const device = isMobileOrTable ? 'mobile' : 'desktop';
      if (sendToNewRelic) {
        newrelic.addPageAction('AddToBag', {
          ...product,
          error,
          nextDevice: device,
          location: path,
        });
      }
    }

    try {
      const { data } = await addProductToBag({
        variables: {
          input: { ...product },
        },
      });

      // open the minibag and update context with new bag
      if (data) {
        newRelicSender('false');
        setAddToBagError(false);
        bagDispatch(updateAddProductToBag(data.addProductToBag));
        toggleMiniBag(true);
        bagDispatch(updateAddingStatus(AddingStatus.Added));

        // close the minibag after 5 seconds
        setTimeout(() => {
          toggleMiniBag(false);
        }, 5000);

        if (isMobileOrTable && pageName !== 'Cart') {
          // Scroll the user to the top of the page
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
      }
    } catch (e) {
      newRelicSender('true');
      setAddToBagError(true);
      bagDispatch(updateAddingStatus(AddingStatus.Error))

    } finally {
      setIsAddingToBag(false);
    }
  };

  useEffect(() => {
    if (addingStatus === AddingStatus.Added) {
      setTimeout(() => {
        bagDispatch(updateAddingStatus(null));
      }, 5000);
    }
  }, [addingStatus]);

  return {
    addToBag,
    addToBagError,
    isAddingToBag,
  };
};

export default useAddToBag;
