import React, { FC, ReactElement } from 'react';

import PopupTrigger from '../../PopupTrigger';
import { StyledSizeGuideButton, StyledSizeGuideButtonKgRedesign } from './SizeGuideTriggerStyles';

interface SizeGuideTriggerStructureProps {
  target: string;
  pageName: string | null;
  featureKgRedesign?: boolean;
}

const SizeGuideTriggerStructure: FC<SizeGuideTriggerStructureProps> = ({
  target,
  pageName,
  featureKgRedesign,
}: SizeGuideTriggerStructureProps): ReactElement => (
  <PopupTrigger target={target}>
    {featureKgRedesign ? (
      <StyledSizeGuideButtonKgRedesign
        data-hookid={`${pageName}SizeGuideLink`}
        type="button"
      >
        Size Guide
      </StyledSizeGuideButtonKgRedesign>
    ) : (
      <StyledSizeGuideButton
        data-hookid={`${pageName}SizeGuideLink`}
        type="button"
      >
        Size Guide
      </StyledSizeGuideButton>
    )}



  </PopupTrigger>
);

export default SizeGuideTriggerStructure;
