import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectPageName, selectToggles } from '../../features/static';
import { SalesFlagType } from '../../types/custom-types';
import { isSalesFlagType } from '../../types/type-checkers';
import SalesFlagStructure from './SalesFlagStructure';

export interface SalesFlagProps {
  flags?: {
    [key in SalesFlagType]: boolean;
  };
  isNewInPLP: boolean | null;
  lineNumber?: string;
  percentOff?: number | null;
}

const SalesFlag: FC<SalesFlagProps> = ({ flags, isNewInPLP, lineNumber, percentOff }) => {
  const toggles = useSelector(selectToggles);
  const pageName = useSelector(selectPageName);
  const { featureLimitedEditionFlagProducts, featureHideOfferFlag, featureUsExclusiveFlagProducts, featureKgRedesign } = toggles ?? {};

  const getCurrentFlag = (): SalesFlagType | string | undefined => {
    if (lineNumber && pageName === 'plp') {
      
      if (featureLimitedEditionFlagProducts) {
        const productsLimited = featureLimitedEditionFlagProducts.split(',');
        if (productsLimited.find((product: string) => lineNumber === product.trim())) {
          return 'LIMITED EDITION';
        }
      }

      if (featureUsExclusiveFlagProducts) {
        const productsUsExclusive = featureUsExclusiveFlagProducts.split(',');
        if (productsUsExclusive.find((product: string) => lineNumber === product.trim())) {
          return 'US EXCLUSIVE';
        }
      }
    }

    const newFlags = { ...flags };

    if (featureHideOfferFlag) {
      newFlags.offer = false;
    }

    const flag = Object.keys(newFlags).find(
      (key) => isSalesFlagType(key) && newFlags[key] === true,
    );

    if (flag === 'newIn') {
      if (isNewInPLP) {
        return undefined;
      }
      return 'new in';
    }
    if (flag === 'priceDrop') {
      return 'price drop';
    }

    return flag;
  };

  const componentProps = {
    flag: getCurrentFlag(),
    percentOff,
    featureKgRedesign,
  };

  return componentProps.flag || percentOff ? <SalesFlagStructure {...componentProps} /> : null;
};

export default SalesFlag;
