import React, { FC } from 'react';

import { SalesFlagType } from '../../types/custom-types';
import { StyledTag } from './SalesFlagStyles';

interface SalesFlagStructureProps {
  flag?: SalesFlagType | string;
  percentOff?: number | null;
  featureKgRedesign: boolean;
}

const SalesFlagStructure: FC<SalesFlagStructureProps> = ({
  flag,
  percentOff,
  featureKgRedesign,
}) => (
  <StyledTag data-hookid="roundelFlag" $label={flag} $featureKgRedesign={featureKgRedesign}>
    {flag}{flag && percentOff && ` | `}{percentOff && `${percentOff}% Off`}
  </StyledTag>
);

export default SalesFlagStructure;
