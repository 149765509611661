import React, { Children, cloneElement, FC, ReactElement } from 'react';
import { useDispatch } from 'react-redux';

import { PopUpActionData, updateOpenPopUp } from '../../features/popUp/actions';
import PopupTriggerStructure from './PopupTriggerStructure';

interface Props {
  children: ReactElement;
  target?: string;
  imageId?: number;
}

const PopupTrigger: FC<Props> = ({ children, target = '', imageId }) => {
  const dispatch = useDispatch();

  const data: PopUpActionData = {
    target,
    open: true,
    imageId,
  };

  const pushTargetId = () => {
    dispatch(updateOpenPopUp({ ...data, position: window.scrollY, imageId }));
  };

  const updatedChildren = Children.map(children, (child: ReactElement) =>
    cloneElement(child, {
      onClick: () => pushTargetId(),
    }),
  );

  const componentProps = {
    childWithClick: updatedChildren,
  };
  return <> {updatedChildren && <PopupTriggerStructure {...componentProps} />}</>;
};

export default PopupTrigger;
