import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentProduct } from '../../../features/currentProduct';
import usePageName from '../../../utils/customHooks/usePageName';
import SizeGuideTriggerStructure from './SizeGuideTriggerStructure';

interface SizeGuideTriggerProps {
  target: string;
  featureKgRedesign?: boolean;
}

const SizeGuideTrigger: FC<SizeGuideTriggerProps> = ({
  target,
  featureKgRedesign,
}: SizeGuideTriggerProps): ReactElement => {
  const currentProduct = useSelector(selectCurrentProduct);
  const [showSizeGuideTrigger, setShowSizeGuideTrigger] = useState(true);

  const { pageName } = usePageName();

  useEffect(() => {
    if (currentProduct) {
      setShowSizeGuideTrigger(['01', '02', '07'].includes(currentProduct.productGenderCode));
    }
  }, [currentProduct]);

  const componentProps = {
    target,
    pageName,
    featureKgRedesign,
  };

  return <>{showSizeGuideTrigger && <SizeGuideTriggerStructure {...componentProps} />}</>;
};

export default SizeGuideTrigger;
