import React, { FC } from 'react';

import { StyledPrice, StyledPrices, StyledPriceValues, StyledRRP } from './PriceStyles';
import { PriceProps } from '.';

interface PriceStructureProps extends PriceProps {
  pageName: string;
  hasWasPrice: boolean;
  currencySymbol: string;
  addFixedPrice: boolean;
  fpData: string;
  isRedesign: boolean;
}

const PriceStructure: FC<PriceStructureProps> = ({
  was,
  wasFormatted,
  now,
  nowFormatted,
  pageName,
  sale,
  hasWasPrice,
  currencySymbol,
  addFixedPrice,
  fpData,
  isRedesign,
}) => (
  <>
    <StyledPriceValues data-hookid={`${pageName}ProductPriceValues`}>
      <StyledPrices data-hookid={`${pageName}ProductPrices`} $was={hasWasPrice} $isRedesign={isRedesign}>
        {hasWasPrice && (
          <StyledRRP
            data-hookid={`${pageName}ProductPriceWasPrice`}
            {...(pageName === 'pdp' && { key: `was-price-${was}` })}
          >
            {isRedesign && wasFormatted ? (
              <>
                {wasFormatted}
              </>
            ) : (
              <>
                {currencySymbol}
                {was}
              </>
            )}

          </StyledRRP>
        )}
        {addFixedPrice ? (
          <StyledPrice
            data-hookid={`${pageName}ProductPrice`}
            $sale={sale}
            data-bp={now}
            data-fp={fpData}
            {...(pageName === 'pdp' && { key: `now-price-${now}` })}
            $isRedesign={isRedesign}
          >
            {isRedesign && nowFormatted ? (
              <>
                {nowFormatted}
              </>
            ) : (
              <>
                {currencySymbol}
                {now}
              </>
            )}
          </StyledPrice>
        ) : (
          <StyledPrice
            data-hookid={`${pageName}ProductPrice`}
            $sale={sale}
            data-bp={now}
            {...(pageName === 'pdp' && { key: `now-price-${now}` })}
            $isRedesign={isRedesign}
          >
            {isRedesign && nowFormatted ? (
              <>
                {nowFormatted}
              </>
            ) : (
              <>
                {currencySymbol}
                {now}
              </>
            )}
          </StyledPrice>
        )}
      </StyledPrices>
    </StyledPriceValues>
  </>
);

export default PriceStructure;
