const settings = {
  arrows: true,
  arrowsShowOnHoverOnly: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: false,
  swipe: true,
};

export default settings;
