import { ProductPrice } from '../../../types/middleware-types';

export const getPricePercentOff = (priceData: ProductPrice): number | null => {
  const { was, now } = priceData || {};

  if (was) {
    try {
      const percentOff = Math.round((Number(was) - Number(now)) / Number(was) * 100);
      const roundedPercentOff = percentOff - percentOff % 5;
      return roundedPercentOff > 0 ? roundedPercentOff : null;
    }
    catch (e) {
      return null;
    }
  }

  return null;
}