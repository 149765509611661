import React, { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes as faTimesRegular } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../basic/Buttons';
import { ProductViewType, QuickBuySize } from '../../types/custom-types';
import { AddingStatus } from '../../types/state-types';
import SizeTypeToggle from '../Product/ProductOptions/SizeTypeToggle';
import {
  StyledErrorMessage,
  StyledQuickBuy,
  StyledQuickBuyInner,
  StyledSizesContainer,
} from './QuickBuyStyles';

interface QuickBuyStructureProps {
  sizes: QuickBuySize[];
  closeQuickAdd: () => void;
  selectedSize: string;
  setSelectedSize: (sku: string) => void;
  addToBag: () => Promise<void>;
  addingStatus: AddingStatus | null;
  productView: ProductViewType | null | undefined;
  pageName: string | undefined;
  sizeType: string;
  setSizeType: (type: string) => void;
  currentSite: string | undefined;
  errorMessageOnly: boolean;
  buttonText: string;
  showSizeTypeToggle: boolean;
}

const QuickBuyStructure: FC<QuickBuyStructureProps> = ({
  sizes,
  closeQuickAdd,
  selectedSize,
  setSelectedSize,
  addToBag,
  addingStatus,
  productView,
  pageName,
  sizeType,
  setSizeType,
  currentSite,
  errorMessageOnly,
  buttonText,
  showSizeTypeToggle
}) => {
  const notInStock = sizes.every((size) => !size.inStock);
  return (
    <StyledQuickBuy $currentSite={currentSite} data-hookid={`${pageName}QuickBuy`}>
      {addingStatus === AddingStatus.Error || (sizes && sizes.length > 1 && sizes[0].label) ? (
        <>
          {productView && (
            <StyledSizesContainer
              $productView={productView}
              $hasError={addingStatus === AddingStatus.Error}
              $errorMessageOnly={errorMessageOnly}
            >
              <span role="presentation" onClick={closeQuickAdd}>
                <FontAwesomeIcon icon={faTimesRegular as IconProp} />
              </span>
              {!errorMessageOnly && showSizeTypeToggle && (
                <SizeTypeToggle sizeType={sizeType} handleSizeTypeChange={setSizeType} />
              )}
              {sizes?.length > 1 && (
                <StyledQuickBuyInner $currentSite={currentSite}>
                  {sizes?.map(
                    (size) =>
                      size.label && (
                        <div key={size.label}>
                          <Button
                            passedFunctions={() => setSelectedSize(size.sku)}
                            className={selectedSize === size.sku ? 'active' : ''}
                            disabled={!size.inStock}
                            btnType="secondary"
                            type="button"
                            key={size.label}
                            data-hookid="size-btn"
                            value={size.sku}
                          >
                            {size.label}
                          </Button>
                        </div>
                      ),
                  )}
                </StyledQuickBuyInner>
              )}
              {(addingStatus === AddingStatus.Error) && <StyledErrorMessage>Unable to add to bag</StyledErrorMessage>}
            </StyledSizesContainer>
          )}
          <Button
            data-hookid={`${pageName}QuickBuyAddToBag`}
            className="add-to-bag"
            passedFunctions={addToBag}
            data-testid="add-to-bag"
            disabled={!selectedSize || notInStock || addingStatus !== null}
            aria-live="assertive"
            type="button"
            btnType="primary"
            currentSite={currentSite}
          >
            {buttonText}
          </Button>
        </>
      ) : (
        <Button
          data-hookid={`${pageName}QuickBuyAddToBag`}
          disabled={!sizes[0].inStock || addingStatus !== null}
          aria-live="assertive"
          className="add-to-bag"
          passedFunctions={addToBag}
          data-testid="no-size"
          type="button"
          btnType="primary"
          currentSite={currentSite}
          value={selectedSize}
        >
          {buttonText}
        </Button>
      )}
    </StyledQuickBuy>
  );
};

export default QuickBuyStructure;
