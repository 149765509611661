import styled from 'styled-components';

interface Props {
  $isActive: boolean;
}

export const StyledSizesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledSizeTypeToggle = styled.button<Props>`
  ${({ theme }) => theme.types.labelStyle};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  text-transform: uppercase;
  line-height: ${({ theme }) => theme.vars.sizeL};
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.vars.black};

  ${({ $isActive, theme }) =>
  $isActive &&
    `
    ${theme.types[theme.pdpContent.sizeTypeToggle]};
  `}
  &:first-of-type {
    position: relative;

    &::after {
      content: "";
      background: ${({ theme }) => theme.vars.black};
      height: ${({ theme }) => theme.vars.sizeL};
      width: 1.25px;
      display: inline-block;
      position: absolute;
      right: 0;
      top: 1px;
    }
  }
`;
