import { ProductPrice } from '../../types/middleware-types';

export const productPriceMock: ProductPrice = {
  now: '19.00',
  nowFormatted: '£19',
  was: '39.00',
  wasFormatted: '£39',
  discount: '20.00',
  discountPercentage: '51.28205128205128',
};

export const productPriceNowMock: ProductPrice = {
  now: '19.00',
  nowFormatted: '19.00',
  was: null,
  wasFormatted: null,
  discount: null,
  discountPercentage: null,
};

export const fpDataMock = { MX: 4499 };

export default productPriceMock;
