const getSizeTypes = (): [string, string] => {
  if (process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeigerglobal') {
    return ['eu', 'us'];
  } if (process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeigermexico') {
    return ['us', 'mx'];
  }
  return ['uk', 'eu'];
};

export default getSizeTypes;
