import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectAddingStatus } from '../../features/bag';
import { selectProductView } from '../../features/plpContent';
import { selectToggles } from '../../features/static';
import { QuickBuySize } from '../../types/custom-types';
import { AddingStatus } from '../../types/state-types';
import usePageName from '../../utils/customHooks/usePageName';
import QuickBuyStructure from './QuickBuyStructure';

export interface QuickBuyProps {
  sizes: QuickBuySize[];
  closeQuickAdd: () => void;
  selectedSize: string;
  setSelectedSize: (sku: string) => void;
  addToBag: () => Promise<void>;
  sizeType: string;
  setSizeType: (type: string) => void;
}

const QuickBuy: FC<QuickBuyProps> = ({
  sizes,
  closeQuickAdd,
  selectedSize,
  setSelectedSize,
  addToBag,
  sizeType,
  setSizeType,
}) => {
  const { NEXT_PUBLIC_SITE: currentSite } = process.env;
  const productView = useSelector(selectProductView);
  const addingStatus = useSelector(selectAddingStatus);

  const toggles = useSelector(selectToggles);
  const { pageName } = usePageName();

  const showSizeTypeToggle = Object.keys(toggles).length > 0 && toggles.featureSizeSwitcher;

  const buttonText = 
    (addingStatus === AddingStatus.Adding && 'Adding To Bag') || (addingStatus === AddingStatus.Added && 'Added To Bag') || 'Add To Bag';

  const componentProps = {
    sizes,
    closeQuickAdd,
    selectedSize,
    setSelectedSize,
    addToBag,
    productView,
    pageName,
    sizeType,
    setSizeType,
    addingStatus,
    currentSite,
    errorMessageOnly: addingStatus === AddingStatus.Error && !(sizes && sizes.length > 1 && sizes[0].label),
    buttonText,
    showSizeTypeToggle
  };

  return <QuickBuyStructure {...componentProps} />;
};

export default QuickBuy;
