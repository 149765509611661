import gql from 'graphql-tag';

import { MINIBAG_PRODUCT_FRAGMENT } from '../fragments/minibagFragments';

export const ADD_PRODUCT_TO_CART = gql`
  mutation AddToBag($input: AddProductToCartInput!) {
    addProductToBag(input: $input) {
      bag {
        id
        itemCount
        items {
          id
          product {
            ...MiniBagProduct
          }
          quantity
        }
        remainingItems
        subTotal
        subTotalFormatted
      }
    }
  }
  ${MINIBAG_PRODUCT_FRAGMENT}
`;

export const ADD_PRODUCT_TO_CART_TOGGLED = gql`
  mutation AddToBag($input: AddProductToCartInput!) {
    addProductToBag(input: $input) {
      bag {
        id
        _id
        itemCount
        items {
          id
          product {
            ...MiniBagProduct
          }
          quantity
        }
        remainingItems
        subTotal
        subTotalFormatted
      }
    }
  }
  ${MINIBAG_PRODUCT_FRAGMENT}
`;