import React, { FC } from 'react'

import { StyledLoading } from './LoadingStyles'

const LoadingStructure: FC = () => {
  return (
    <StyledLoading>
      <span />
      <span />
      <span />
    </StyledLoading>
  )
}

export default LoadingStructure
