import React, { FC, ReactNode } from 'react';

interface Props {
  childWithClick: ReactNode;
}

const PopupTriggerStructure: FC<Props> = ({
  childWithClick,
}) => (
  <>
    {childWithClick}
  </>
);

export default PopupTriggerStructure;
