import gql from 'graphql-tag';

import { PRODUCT_IMAGE_FRAGMENT } from '../fragments/basicFragments';

export const GET_PRODUCT_MEDIA = gql`
  query ProductMedia($lineNumber: LineNumber!) {
    productMedia(lineNumber: $lineNumber) {
      images {
        ...ProductImage
      }
    }
  }
  ${PRODUCT_IMAGE_FRAGMENT}
`;