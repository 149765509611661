import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectProductLineNumber } from '../../features/currentProduct';
import { selectCurrencyData, selectToggles } from '../../features/static';
import { fpDataMock } from '../../mock-data/product/productPrice'
import { Maybe } from '../../types/middleware-types';
import usePageName from '../../utils/customHooks/usePageName';
import PriceStructure from './PriceStructure';

export interface PriceProps {
  was?: Maybe<string>;
  wasFormatted?:Maybe<string>;
  now: string;
  nowFormatted: string;
  sale: boolean;
  lineNumber?: string
  featureKgRedesign?: boolean;
}

const Price: FC<PriceProps> = ({ was, wasFormatted, now, nowFormatted, sale, lineNumber }) => {
  const { pageName } = usePageName();
  const toggles = useSelector(selectToggles);
  const isRedesign = toggles.featureKgRedesign;
  const showWasPrice = !toggles.featureHideWasPrice;
  const currencyData = useSelector(selectCurrencyData);
  const currentLineNumber = useSelector(selectProductLineNumber);
  const isProduct = lineNumber === '8489000609' || currentLineNumber === '8489000609'

  const hasWasPrice = showWasPrice && !!was && parseFloat(was) > parseFloat(now);
  const hasNoValues = (was === undefined || was === null) && (now === undefined || now === null);

  const componentProps = {
    was,
    wasFormatted,
    now,
    nowFormatted,
    pageName,
    sale,
    hasWasPrice,
    currencySymbol: currencyData?.symbol,
    addFixedPrice: toggles.globalEFixedPrice && isProduct,
    fpData: JSON.stringify(fpDataMock),
    isRedesign
  };

  return <>{!hasNoValues && <PriceStructure {...componentProps} />}</>;
};

export default Price;
