import React, { FC, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import useMobileWatcher from '../../../../utils/customHooks/useMobileWatcher';
import usePageName from '../../../../utils/customHooks/usePageName';
import getSizeTypes from '../../../../utils/getters/getSizeTypes';
import SizeTypeToggleStructure from './SizeTypeToggleStructure';

interface SizeTypeToggleProps {
  handleSizeTypeChange: (type: string) => void;
  sizeType: string;
  isFindInStore?: boolean;
  showSizeTypeToggle?: boolean;
}

const SizeTypeToggle: FC<SizeTypeToggleProps> = ({
  handleSizeTypeChange,
  sizeType,
  isFindInStore,
  showSizeTypeToggle,
}) => {
  const [sizeGuideToggle, setSizeGuideToggle] = useState(true);

  const types = getSizeTypes();
  const theme = useTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const { pageName } = usePageName();

  useEffect(() => {
    if (pageName === 'plp' || pageName === 'plpSearch') {
      setSizeGuideToggle(false);
    }
  }, [pageName]);

  const componentProps = {
    handleSizeTypeChange,
    sizeType,
    types,
    pageName,
    isDesktop,
    isFindInStore,
    sizeGuideToggle,
    showSizeTypeToggle,
  };
  return <SizeTypeToggleStructure {...componentProps} />;
};

export default SizeTypeToggle;
