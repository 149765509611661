import { gql } from '@apollo/client';

export const GET_PRODUCT_SIZE_AVAILABILITY = gql`
  query ProductSizeAvailability($lineNumber: LineNumber!) {
    productSizeAvailability(lineNumber: $lineNumber) {
      sku
      stockMessage
      inStock
      fit
      labels {
        original
        eu
        uk
        us
      }
    }
  }
`;
