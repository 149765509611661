import styled from 'styled-components';

import { ProductViewType } from '../../types/custom-types';
import { StyledSizesContainer as SizeTypeToggle } from '../Product/ProductOptions/SizeTypeToggle/SizeTypeToggleStyles';

interface Props {
  $productView: ProductViewType;
  $hasError: boolean;
  $errorMessageOnly: boolean;
}

interface CurrentSiteProps {
  $currentSite?: string;
}

export const StyledQuickBuy = styled.div<CurrentSiteProps>`
  position: absolute;
  bottom: 0;
  z-index: 9;
  width: 100%;

  button {
    &.add-to-bag {
      ${({ $currentSite, theme }) =>
  $currentSite === 'carvela' &&
        `
        background: ${theme.vars.black};
        color: ${theme.vars.white};
        border: 1px solid ${theme.vars.black};
        border-top: none;
      `};
      &:disabled {
        border: 0;
        opacity: 1;
        background-color: ${({ theme }) => theme.productCard.quickBuy.disabledBackground};
      }
    }
  }
`;

export const StyledSizesContainer = styled.div<Props>`
  border: 1px solid ${({ theme }) => theme.vars.greyDark};
  padding: ${({ $productView, $hasError }) =>
  $productView === 'large_view' || $hasError ? '1.6rem 0.5rem 0.2em' : '1.6rem 0.3rem'};
  padding-top: ${({ $errorMessageOnly }) => ($errorMessageOnly ? '1rem' : '3rem')};
  background: ${({ theme }) => theme.vars.white};
  position: relative;
  box-sizing: border-box;

  span {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    font-size: ${({ theme }) => theme.vars.sizeM};
    cursor: pointer;
  }
  ${SizeTypeToggle} {
    position: absolute;
    top: 0.75rem;
  }
`;

export const StyledQuickBuyInner = styled.div<CurrentSiteProps>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  div {
    box-sizing: border-box;
    margin: 1.5%;
    flex: 0 0 22%;

    button {
      ${({ theme }) => theme.types.ctaTextStyles};
      display: block;
      padding: 0;
      width: 100%;
      text-align: center;
      border: 1px solid ${({ theme }) => theme.vars.greyDark};
      ${({ $currentSite }) =>
  $currentSite === 'carvela' &&
        `
        height: 2.8rem;
      `};

      &.active {
        background: ${({ theme }) => theme.productCard.quickBuy.activeBackground};
        color: ${({ theme }) => theme.vars.white};
        border: none;
        ${({ $currentSite, theme }) =>
          $currentSite === 'carvela' &&
          `
        background: ${theme.vars.black};
        color: ${theme.vars.white};
        border: 1px solid ${theme.vars.black};
      `};
      }

      &:disabled {
        &:hover {
          color: ${({ theme }) => theme.vars.black};
          border-color: transparent;
        }
      }
    }
  }
`;

export const StyledErrorMessage = styled.h2`
  ${({ theme }) => theme.types.labelStyle};
  color: ${({ theme }) => theme.vars.redDark};
  text-align: left;
  text-transform: unset;
  margin: 0 0.5rem 0.2rem;

  &::before {
    content: "*";
  }
`;
